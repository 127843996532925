import 'magnific-popup';
require( 'owl.carousel' );
require( 'select2' );

import nav from './modules/nav';
import home from './modules/home';
import talent from './modules/talent';
import videos from './modules/videos';

$(document).ready(function($) {

  nav();

  if ($('body').hasClass('page-template-home')) {
    home();
  }

  if ($('body').hasClass('page-template-talent')) {
    talent();
  }

  if ($('body').hasClass('page-template-videos')) {
    videos();
  }
  
  // ADA
  $('.heateor_sss_email').attr('href', '#');
});