export default function home() {
  const home_hero = $('.home-hero');
  
  $(window).on('load', function(event) {
	  $('.owl-dot').each(function(index) {
		  $(this).append('<span class="sr-only">Slide '+ (index + 1) +'</span>');
	  });
  });
  
  home_hero.owlCarousel({
    items: 1,
    dotsEach: true,
    loop: true,
    nav: false,
    smartSpeed: 1000,
    mouseDrag: true,
    autoplay: false
  });

  // $('.home-videos').owlCarousel({
  //   items: 1,
  //   center: true,
  //   margin: 60,
  //   dotsEach: true,
  //   loop: true,
  //   smartSpeed: 1000,
  //   nav: true,
  //   mouseDrag: true,
  //   autoplay: false,
  //   responsive: {
  //     767: {
  //       items: 2
  //     }
  //   }
  // });

  setTimeout(function(){
    $('.landing').fadeOut('slow');
  }, 2000);

}